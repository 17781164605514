import React, { memo } from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';

const Card = ({
  title,
  desc,
  imgPath,
  isRight,
}: {
  title: string;
  desc: string;
  imgPath: string;
  isRight?: boolean;
}) => (
  <div
    className={styles.card}
    style={{
      backgroundImage: `url(${imgPath})`,
      aspectRatio: isRight ? '584/362' : '584/248',
    }}
  >
    <div className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
    <p
      className={styles.desc}
      style={{ width: isRight ? '60%' : '45%' }}
      dangerouslySetInnerHTML={{ __html: desc }}
    />
  </div>
);

const MemoizedCard = memo(Card);

const DetailShowContent = () => {
  const { t } = useTranslation('common');

  const items = [
    {
      title: t('Living in a Geopolitical Hotspot'),
      desc: t('Areas with geopolitical tensions, like Taiwan, Ukraine, the Middle East, etc.'),
      imgPath: '/images/planbowl/home-ifyouare-4.webp',
    },
    {
      title: t('Interested in Survival Shows'),
      desc: t('Passionate about survival reality shows and related skills.'),
      imgPath: '/images/planbowl/home-ifyouare-3.webp',
    },
    {
      title: t('Living in a High Crime Area'),
      desc: t('Neighborhoods with rising crime rates.'),
      imgPath: '/images/planbowl/home-ifyouare-2.webp',
    },
    {
      title: t('Witnessing Disasters'),
      desc: t('Recently experienced nearby disasters or significant damage.'),
      imgPath: '/images/planbowl/home-ifyouare-1.webp',
      isRight: true,
    },
    {
      title: t('Active Prepper'),
      desc: t('Active in online survival and preparedness communities.'),
      imgPath: '/images/planbowl/home-ifyouare-0.webp',
      isRight: true,
    },
  ];

  return (
    <div className={styles.content}>
      <h2 className={styles.contentTitle}>{t('IF YOU ARE...')}</h2>
      <div className={styles.bottomContainer}>
        <div className={styles.leftSubContainer}>
          {items.slice(0, 3).map((item, index) => (
            <MemoizedCard key={index} {...item} />
          ))}
        </div>

        <div className={styles.leftSubContainer}>
          {items.slice(3).map((item, index) => (
            <MemoizedCard key={index} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DetailShowContent;
