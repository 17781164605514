import React, { useRef, useState } from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
// import { useRouter } from 'next/router';
import DetailShowContent from '../DetailShowContent';
import { CommonBaseContent } from '@/components/Layouts/CommonBackContent';

const FeaturesContent = () => {
  const { t } = useTranslation();

  const dataList = [
    {
      title: t('Covering Over 10+ Emergency Scenarios'),
      desc: t(
        'From everyday incidents to major disasters like accidents, natural calamities, and even war... Our detailed preparation modules include SOPs, essential checklists, and contact cards.',
      ),
      img: '/images/planbowl/home-top.gif',
    },
    {
      title: t('Streamlined and Efficient'),
      desc: t(
        'Forget bulky manuals. Planbowl’s concise guide packs all the essential, lifesaving information into just a few pages, ready in under 1 minute.',
      ),
      img: '/images/planbowl/home-icon-1.webp',
    },
    {
      title: t('No One-Size-Fits-All'),
      desc: t(
        'No generic solutions. Based on your specific environment and situation, get your personalized guide. Every piece of advice is fine-tuned to your unique needs, making it practical and useful.',
      ),
      img: '/images/planbowl/home-icon-2.webp',
    },
    {
      title: t('A Lifelong Safety Investment'),
      desc: t(
        `One-time purchase with lifetime updates and editing. Available in digital and physical formats. This isn’t just a guide; it’s an investment in your safety and the safety of those you love.`,
      ),
      img: '/images/planbowl/home-icon-0.webp',
    },
  ];

  return (
    <div className={styles.content}>
      <div className="" style={{ width: '1028px', margin: '0 auto', textAlign: 'center' }}></div>
      <CommonBaseContent>
        <h2>{t('AND MORE..')}</h2>
        <p className={styles.contentDesc}>
          {t(
            'Planbowl is your shield against emergencies and disasters, ensuring the safety of what matters most.',
          )}
        </p>
        <div className={styles.imgContainer}>
          <img src="/images/planbowl/home-icon-4.webp" alt="" />
        </div>
        <DetailShowContent />
      </CommonBaseContent>
      <h2>
        <span>{t(`Stay Ready to Survive Any Situation with`)} </span>
        <span style={{ color: 'var(--app-primary-color)' }}>{t(`Planbowl`)}</span>
      </h2>

      <div className={styles.container}>
        {dataList?.map((item, index) => {
          return (
            <div key={item?.title} className={styles.card}>
              <div className={styles.cardContainer}>
                <div className={styles.leftCardContent}>
                  <p className={styles.title}>{item?.title}</p>
                  <p className={styles.desc}>{item?.desc}</p>
                </div>
                <div className={styles.rightCardContent}>
                  <img src={item?.img} alt={'icon'} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FeaturesContent;
